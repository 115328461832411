/* istanbul ignore next */
import Router from 'next/router'
import cookie from 'cookie'
import cookies from 'js-cookie'
import Constants from '@core/utils/constants'
import errorHandler from '@core/utils/errorHandler'
import '@core/project'
import storage from './async-storage-api'
import { IncomingMessage } from 'http'
import Cognito from '@core/cognito'
import flagsmith from 'flagsmith'

export const APICommon = {
  isMobile: () => false,
  platform: 'web',
  flagsmith,
  storage,
  ajaxHandler(type, e) {
    return { type, error: errorHandler(e) }
  },
  logout() {
    Cognito.logout()
    cookies.remove('token')
    Router.replace('/', undefined, { shallow: true })
    // @ts-ignore
    if (window.fcWidget) {
      // @ts-ignore
      window.fcWidget.destroy()
    }

    // For web we have to go to the cognito logout URL so that SSO users are logged out
    const clientId = Project.cognito.userPoolWebClientId
    const logoutUri = `${document.location.origin}/oauth/web-logout`

    if (
      // This if is here because for branch deployments, we can't whitelist the vercel url as a redirect for cognito
      // https://siteassist-frontend-6yv5k427q-siteassist.vercel.app
      !document.location.origin.endsWith('siteassist.vercel.app') &&
      // Testcafe uses a local ip for the url
      !Constants.E2E
    ) {
      window.location.assign(
        `https://siteassist.auth.eu-west-2.amazoncognito.com/logout?client_id=${clientId}&logout_uri=${logoutUri}`,
      )
    }
  },
  getStoredToken(req?: IncomingMessage) {
    return API.storage.getItem('token', req)
  },
  getStoredUser(req) {
    return API.storage.getItem('user', req)
  },
  getStoredRefreshToken(req) {
    return API.storage.getItem('refreshToken', req)
  },
  setStoredRefreshToken(v) {
    return API.storage.setItem('refreshToken', v)
  },
  getStoredLocale(req) {
    if (req) {
      // Attempt to get locale saved cookie
      const parsedCookies = cookie.parse(req.headers.cookie || '')
      if (parsedCookies.locale) {
        return parsedCookies.locale
      }
      // Attempt to retrieve local from Accept-Language headers
      if (req.headers && req.headers['accept-language']) {
        const parsedLocale = req.headers['accept-language'].split(',')[0]
        if (parsedLocale) {
          return parsedLocale
        }
      }
    }
    return Constants.defaultLocale
  },
  setStoredToken(v) {
    return API.storage.setItem('token', v)
  },
  identify(id) {},
  register(email, firstName, lastName) {},
  reset() {},
  log(namespace: string, ...args: any[]) {
    if (Project.logs[namespace]) {
      // eslint-disable-next-line no-console
      console.log.apply(this, [namespace, ...args])
    }
  },
  auth: {
    Cognito,
  },
  nativeMigrations: {},
}
