import React from 'react'
import { Drawer } from '@mui/material'
import { Flex } from '@fundamentals'
import { styled } from '@mui/material/styles'
import {
  DrawerAnchorTypes,
  DrawerVariantTypes,
} from '@common/GlobalDrawer/types'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(() => ({
  flexGrow: 1,
  transition: 'all 0.6s ease',
  maxWidth: '100%',
}))

const flexDirectionAnchor = (anchor: DrawerAnchorTypes) => {
  switch (anchor) {
    case 'left':
      return 'row'
    case 'right':
      return 'row-reverse'
    case 'top':
      return 'column'
    case 'bottom':
      return 'column-reverse'
    default:
      return 'row'
  }
}

type DrawerWrapperProps = {
  open: boolean
  onClose: () => void
  component: React.ReactNode
  anchor?: DrawerAnchorTypes
  variant?: DrawerVariantTypes
  children: React.ReactNode
}

export const WithDrawerLayout: React.FC<DrawerWrapperProps> = ({
  children,
  open,
  onClose,
  component,
  anchor = 'right',
  variant = 'temporary',
}) => {
  return (
    <Flex flex={1} flexDirection={() => flexDirectionAnchor(anchor)}>
      <Drawer
        open={open}
        onClose={onClose}
        ModalProps={{
          keepMounted: false,
        }}
        sx={{
          width: open ? 'auto' : '1px',
          transition: '1.6s ease',
          '& .MuiDrawer-paper': {
            position: variant === 'persistent' && 'relative',
            boxSizing: 'border-box',
          },
        }}
        anchor={anchor}
        variant={variant}
      >
        {open && component}
      </Drawer>
      <Main open={open}>{children}</Main>
    </Flex>
  )
}
