import { localizedStrings } from '@core/strings'

export type RegisterTemplatePropertyType =
  | 'STRING'
  | 'DATE'
  | 'NUMBER'
  | 'SELECT'
  | 'LINK'
  | 'INCREMENTAL_ID'
  | 'USER'
  | 'DIAGRAM'

export type RegisterTemplateFilterPropertyType = 'NEW_FILTER'

export type RegisterTemplateValidationPropertyType = 'NEW_VALIDATION'

export const registerTypes = {
  STRING: 'STRING',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  LINK: 'LINK',
  INCREMENTAL_ID: 'INCREMENTAL_ID',
  USER: 'USER',
  DIAGRAM: 'DIAGRAM',
}
export const registerTypeProperties: {
  type: RegisterTemplatePropertyType
  name: string
}[] = [
  { type: 'STRING', name: localizedStrings.text },
  { type: 'DATE', name: localizedStrings.date },
  { type: 'SELECT', name: localizedStrings.select },
  { type: 'NUMBER', name: localizedStrings.numeric },
  { type: 'LINK', name: localizedStrings.link },
  { type: 'INCREMENTAL_ID', name: localizedStrings.incrementalId },
  { type: 'USER', name: localizedStrings.userSelect },
]
