import { TemplateRuleCondition } from '@core/entities/template/TemplateRuleCondition'
import { IPermitTemplateApprovalRule } from '@core/entities/permit-template-editor/IPermitTemplateApprovalRule'
import { Entity } from '@core/entities/entity'
import { Type } from 'class-transformer'

export class PermitTemplateApprovalRule extends Entity<IPermitTemplateApprovalRule> {
  @Type(() => TemplateRuleCondition)
  conditions?: Array<TemplateRuleCondition>
  approvals?: {
    id: string
  }[]
}
