import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types'
import React, { useCallback, useState } from 'react'
import {
  DatePicker as MUIDatePicker,
  DateTimePicker as MuiDateTimePicker,
} from '@mui/x-date-pickers'
import { InputAdornment } from '@common/material/InputAdornment'
import { Clear } from '@mui/icons-material'
import { IconButton } from '@common/material/IconButton'
import { DateTimePickerProps } from '@mui/x-date-pickers'
import { TextInput } from './TextInput'

type DatePicker<TDate, T extends boolean> = (T extends false | undefined
  ? DatePickerProps<TDate>
  : DateTimePickerProps<TDate>) &
  React.RefAttributes<HTMLDivElement> & {
    required?: boolean
    error?: boolean
    withClearButton?: boolean
    withTime?: T
    UTC?: boolean
    dataTest?: string
    name?: string
  }

export const DatePicker: <TDate, WithTime extends boolean>(
  props: DatePicker<TDate, WithTime>,
) => React.ReactElement = ({
  slotProps,
  slots,
  error,
  required,
  withTime,
  withClearButton,
  UTC,
  dataTest,
  disabled,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const handleClear = useCallback(() => {
    // @ts-ignore
    rest.onChange('')
  }, [])

  const Component = withTime ? MuiDateTimePicker : MUIDatePicker

  if (Constants.E2E) {
    const { name, value } = rest
    const [testDateValue, setTestDateValue] = React.useState('')

    if (name === 'startsOn' && testDateValue.length < 1) {
      setTestDateValue(value.toString())
    } else if (name === 'endsOn' && testDateValue.length < 1 && value) {
      setTestDateValue(value.toString())
    }

    return (
      <TextInput
        {...{ 'data-test': dataTest, name }}
        value={testDateValue}
        sx={{ mr: 1 }}
        onChange={(e) => {
          const date = Utils.safeParseEventValue(e)

          if (!isNaN(new Date(date).valueOf())) {
            setTestDateValue(new Date(date).toString())
            // @ts-ignore
            rest.onChange(new Date(date))
          }
        }}
      />
    )
  }

  return (
    <Component
      format={`DD MMM YYYY${withTime ? ' HH:mm' : ''}`}
      ampm={false}
      disabled={disabled}
      timezone={UTC ? 'UTC' : undefined}
      open={open}
      onClose={() => setOpen(false)}
      slotProps={{
        ...slotProps,
        textField: {
          onClick: () => setOpen(true),
          // @ts-ignore
          readOnly: true,
          required: required,
          error: error,
          ...slotProps?.textField,
        },
        actionBar: {
          actions: ['clear'],
          ...slotProps?.actionBar,
        },
      }}
      // @ts-ignore
      slots={{
        inputAdornment:
          withClearButton && !disabled
            ? () => (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      handleClear()
                    }}
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            : () => null,
        ...slots,
      }}
      {...rest}
    />
  )
}
