import { localizedStrings } from '@core/strings'
import React, { FunctionComponent } from 'react'
import Tooltip from '../../../components/Tooltip'
import { ButtonText } from '../../../components/Button'
import { styled } from '@mui/material/styles'
import { Box, Image } from '@fundamentals' // we need this to make JSX compile
import { useTheme } from '@mui/material'

const SignatureCanvas =
  typeof window === 'undefined'
    ? null
    : require('react-signature-canvas').default

type ComponentType = {
  onChange?: () => void
  onEnd?: () => void
  onClear?: () => void
  readonly?: boolean
  sigRef: React.Ref<any>
  optional?: boolean
}

type ComponentTypeEdit = {
  onChange?: () => void
  onEnd?: () => void
  value?: string
  sigRef?: React.Ref<any>
  readonly?: boolean
  isEdit?: boolean
  setIsEdit?: (newVal: boolean) => void
  optional?: boolean
}
const Signature: FunctionComponent<ComponentType> = ({
  sigRef,
  onChange = () => {},
  onClear = () => {},
  onEnd = () => {},
  optional = false,
}) => {
  const theme: any = useTheme()

  return (
    <Box>
      <div className='row mt-3'>
        <div className='col-md-8'>
          <label className='mb-0'>
            <strong>
              {localizedStrings.digitalSignature}
              {optional ? ` (${localizedStrings.optional})` : ''}
            </strong>
          </label>
          {!optional && (
            <Tooltip className='tooltip' title={''}>
              {localizedStrings.digitalSignatureInfo}
            </Tooltip>
          )}
        </div>
        <div className='col-4 text-right'>
          <ButtonText
            data-test='jsCancelEditSignature'
            icon='fas fa-times'
            onClick={() => {
              // @ts-ignore
              sigRef.current?.clear()
              onClear()
            }}
          >
            {localizedStrings.clear}
          </ButtonText>
        </div>
      </div>
      <StyledSignatureBox data-test='jsSignature'>
        <SignatureCanvas
          onBegin={onChange}
          onEnd={onEnd}
          ref={sigRef}
          penColor={theme.palette.primary.main}
        />
      </StyledSignatureBox>
    </Box>
  )
}

export default Signature

export const SignatureEdit: FunctionComponent<ComponentTypeEdit> = ({
  sigRef,
  readonly,
  value,
  isEdit,
  setIsEdit,
  onChange,
  onEnd,
  optional,
}) => {
  const theme: any = useTheme()

  return (
    <Box>
      <div className='row mt-3'>
        <div className='col-md-8'>
          <label className='mb-0'>
            <strong>
              {localizedStrings.digitalSignature}{' '}
              {optional ? ' - ' + localizedStrings.optional : ''}
            </strong>
          </label>
        </div>
        <div className='col-4 text-right'>
          {isEdit ? (
            <ButtonText
              data-test='jsCancelEditSignature'
              icon='fas fa-times'
              onClick={() => {
                setIsEdit(false)
              }}
            >
              {localizedStrings.cancel}
            </ButtonText>
          ) : (
            !readonly && (
              <ButtonText
                data-test='jsEditSignature'
                icon='fas fa-pencil'
                onClick={() => {
                  setIsEdit(true)
                }}
              >
                {localizedStrings.edit}
              </ButtonText>
            )
          )}
        </div>
      </div>
      <StyledSignatureBox data-test='jsSignature'>
        {!isEdit ? (
          // We center the image to center legacy signatures (without strict 2:1 aspect ratio)
          <Box
            display='flex'
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <Image
              src={value}
              style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
              data-test='signature-img'
            />
          </Box>
        ) : (
          <SignatureCanvas
            onBegin={onChange}
            ref={sigRef}
            penColor={theme.palette.primary.main}
            onEnd={onEnd}
          />
        )}
      </StyledSignatureBox>
    </Box>
  )
}

const StyledSignatureBox = styled(Box)`
  background: #f4f6fc;
  border: 1px solid #a4b5d2;
  border-radius: 6px;
  aspect-ratio: 2;
  overflow: hidden;
  box-sizing: border-box;
  max-height: 170px;

  canvas {
    width: 100%;
    height: 100%;
  }
`
