export const ProjectCommon = {
  debug: false,
  api: 'https://api.siteassist.co/api/',
  nestApi: 'https://internal-api.prod.siteassist.co/nest-api/',
  apiBaseUrl: 'https://api.siteassist.co/',
  appUrl: 'https://app.siteassist.co',
  flagsmith: 'N4YCCJMHVwdQGpZtauqx4Z',
  bugsnag: '7fe4e26292804f394b2e11d9d2c918ae',
  intercomAppId: 'd1ld00v1',
  airtableToken:
    'patfHXJ9kFBc3okoD.01f744f845543e9fc9dba245fb8aa8485382ea598140fb3eab3b73a94b18a2e6',
  codepush: {
    android: {
      production: 'Q7M91ypvVtXRjWXpn5tvNjOxaur52oE5tfQ6K',
      staging: 'W6UjlGdZmx4_RjfN_TW6OyubgIVRBepCb5Pzm',
      releaseCandidate: 'kO0FOXAeUZ5-WDnQCIs9hup0gRWWynZmWx_Tp',
      bigLebowski: 'GpFil6wS5_XJJjZO5HslWx-F-x_GTXeV0UHHj',
      fightClub: 'siQpQE1SeRjkOJmZZzs3Rmy-8yXoCoD51S2R4',
      pulpFiction: 'NzMN9SF_m-zHyQANd-rbINNr4CnQss3WD3Ph0',
      memento: 'vsDK_cfCAYzMtuOuRD6pJb9XbcIJmsXYid-2d',
      godFather: 'gaULl7Wtgt6Zka7DqlRznngE3eA47X5ICH8wG',
      theMatrix: 'BFDbQOyQMCoEW9tXkooorO2fjiKQEXWueGKcP',
      forestGump: 'Nx-oM0JzWvWkIk2A1tK4HVQ5r_F1CQrnN00Bw',
      inception: 'M1nm9FjoSaGuTfBCtmJmla5yFSPXO3Hoda2Ww',
      starWars: 'udxK9XGZgjZZ8y3I4AE6CDWl4stxXie7qgkiy',
    },
    ios: {
      production: 'oqnV-mlTIfcFg9gR0xoQEmiqYx85RO3zgDCqV',
      staging: 'NncF0MzPDopsCuo7ESMk9ro1YN5AhVrrSpwrq',
      releaseCandidate: '5VOFeBXlu3AUctcGaZVQq59eVLMjG4uUYf018',
      bigLebowski: '7W9muB07tuQvZTE8QRqMOk3wa149trPY_Cvk2',
      fightClub: 'Gm3xaqhvfTLsq9NeTh72FO59YHh_XtZ7aWzXm',
      pulpFiction: '0wQ0xXf84zTmYwY-t3uUpop1sMbFkJ9SfTU6L',
      memento: 'toSv4QJz4w7B_XZMPjG43_HNeweIv912_zhBR',
      godFather: 'JNfHsfeCEOIuD1JGROAw6TPGytBOUGI0UM-RB',
      theMatrix: '_vbtR6a6qI-LoSK6i_xeicqH-ZTmdZok0aXT7',
      forestGump: 'V__-5PjFMIklxwCOXx88xZsRtbP1nritKLtqe',
      inception: 'o4Psj4LAuoLTQN-Tlctwx38bB0sP-SUqSIKYB',
      starWars: 'xsxPO00fWo7gumMn37hcVjw9odcepFODqYC-A',
    },
  },
  cognito: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_eoRHE2b5K',
    userPoolWebClientId: '7r3283ra2kv4vr8evj2287vk9p',
  },
  cognitoMobile: {
    region: 'eu-west-2',
    userPoolId: 'eu-west-2_eoRHE2b5K',
    userPoolWebClientId: '6ne75nh636nj0brnsdl4bkn5bp',
  },
  logs: {
    DATA: false,
    STORE: false,
    DISPATCHER: false,
    STORAGE: false,
    SERVER: false,
  },
  mobile: {
    // Enabling secured storage for mobile requires installation of https://www.npmjs.com/package/react-native-secured-storage. See README for further instructions on installation
    useSecuredStorage: false,
  },
  gitHash: 'f349889c23',
  mapBox: {
    web: 'pk.eyJ1Ijoic2l0ZWFzc2lzdCIsImEiOiJjbDgzNHVwdGMwMjc3M3dwZnd0NDh4NGtzIn0.67oZZ7f1U7Kizmjj7Ikr4g',
  },
  w3wKey: 'YR9Q1GSS',
  cube: {
    url: 'https://unlikely-mcwilliams.aws-eu-west-1.cubecloudapp.dev/cubejs-api/v1',
  },
  appleAppStoreUrl: 'https://apps.apple.com/app/site-assist/id1554478779',
  googlePlayStoreUrl:
    'https://play.google.com/store/apps/details?id=co.siteassist.mobile',
  segmentWriteKey: 'ujE6f9z8KDHD4FPbnswO9d1nGqebneyU',
  pusherKey: 'b1a9acd80af99ebdda1f',
}
