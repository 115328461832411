import { Entity } from '@core/entities/entity'
import { IPermitTemplateVersion } from '@core/entities/permit-template-editor/IPermitTemplateVersion'
import { plainToInstance, Type } from 'class-transformer'
import { User } from '@core/entities/user'
import {
  TemplateEditorPermitTemplateInitiatorRole,
  TemplateEditorPermitTemplateVersionStatus,
} from '@core/entities/permit-template-editor/types'
import { PermitTemplateApproval } from '@core/entities/permit-template-editor/PermitTemplateApproval'
import { TemplateForm } from '@core/entities/template/TemplateForm'
import { PermitTemplateFinalSignOff } from './PermitTemplateFinalSignOff'
import { TemplateWarning } from '@core/entities/template/TemplateWarnings'
import { TemplateQuestion } from '@core/entities/template/TemplateQuestion'
import { localizedStrings } from '@core/strings'

export class PermitTemplateVersion extends Entity<IPermitTemplateVersion> {
  id?: number
  version: number
  templateId: number
  status: TemplateEditorPermitTemplateVersionStatus
  initiatorRole: TemplateEditorPermitTemplateInitiatorRole
  approverConfirmationText: string

  @Type(() => User)
  createdBy?: User
  createdOn?: string
  @Type(() => User)
  modifiedBy?: User
  modifiedOn?: string

  permitHolderSelfApprove: boolean
  requiresFinalSignoff: boolean
  skipPostPermitChecks: boolean
  requesterConfirmationText: string
  requesterConfirmationCountdown: { time: number; name: string }
  maxDurationEnabled: boolean
  clonable?: boolean
  permitHolderTransferEnabled: boolean
  calculateDurationBasedOnDays: boolean
  allowExtensionBeyondMaxDuration: boolean
  maxDurationSeconds?: number
  maxDurationDays?: number
  requiresApproval?: boolean
  requiresBriefingVerification?: boolean
  requireGeolocationForBriefings?: boolean
  requireGeolocationForPermit?: boolean
  allowApprovalDrafts?: boolean

  @Type(() => PermitTemplateApproval)
  approvals: PermitTemplateApproval[]

  @Type(() => PermitTemplateFinalSignOff)
  finalSignOffs: PermitTemplateFinalSignOff[]

  @Type(() => TemplateForm)
  permitHolderQuestions: TemplateForm
  @Type(() => TemplateForm)
  postPermitChecksQuestions: TemplateForm
  @Type(() => TemplateForm)
  authorisedPersonQuestions: TemplateForm

  @Type(() => TemplateWarning)
  warningsConfig?: Array<TemplateWarning>

  public static new(payload: unknown): PermitTemplateVersion {
    return plainToInstance(PermitTemplateVersion, payload)
  }

  public getQuestionInfo(questionId: string) {
    const getInfo = (templateQuestions: TemplateForm, sourceText) => {
      const groupIndex = templateQuestions.questions.findIndex(
        (questionGroup) => questionGroup.data.find((q) => q.id === questionId),
      )
      const questionIndex = templateQuestions.questions[
        groupIndex
      ].data.findIndex((q) => q.id === questionId)
      return {
        source: sourceText,
        groupIndex,
        questionIndex,
        body: TemplateQuestion.getBodyFormattedText(
          templateQuestions.questions[groupIndex].data[questionIndex].body,
        ),
      }
    }

    if (
      this.permitHolderQuestions.questions.find((questionGroup) =>
        questionGroup.data.find((q) => q.id === questionId),
      )
    ) {
      return getInfo(this.permitHolderQuestions, localizedStrings.permitHolder)
    }
    if (
      this.postPermitChecksQuestions.questions.find((questionGroup) =>
        questionGroup.data.find((q) => q.id === questionId),
      )
    ) {
      return getInfo(
        this.postPermitChecksQuestions,
        localizedStrings.postPermitChecks,
      )
    }
    if (
      this.authorisedPersonQuestions.questions.find((questionGroup) =>
        questionGroup.data.find((q) => q.id === questionId),
      )
    ) {
      return getInfo(
        this.authorisedPersonQuestions,
        localizedStrings.authorisedPerson,
      )
    }
    return null
  }
}
