import { RequestTypes } from '@core/react-query/features/media'
import {
  APIAxiosInstance,
  headersWithAccessToken,
} from '@core/utils/api-axios-instance'

export const s3Upload = async ({
  file,
  folderName,
  accessToken,
}: RequestTypes['uploadMedia']) => {
  const headers = headersWithAccessToken({ accessToken })

  const params = {
    name: `${Date.now().valueOf()}-${file.name}`,
  }
  const { data } = await APIAxiosInstance.post(
    `media/upload/${folderName}`,
    params,
    { headers },
  )

  //!!!Hack Alert: We are using fetch instead of axios for uploading files and media
  // TODO: Find why axios is not working properly for media and file upload
  const fetchOptions = {
    method: 'PUT',
    headers: {
      'content-type': file.type,
    },
    body: file as File,
  }
  await fetch(data.url, fetchOptions)

  return data.id
}
