import { IntercomProvider } from 'react-use-intercom'
import 'project/polyfill'
import 'reflect-metadata'
import App from 'next/app'
import React from 'react'
import 'project/project-components'
import 'ionicons/dist/css/ionicons.css'
import 'styles/Global.scss'
import CompleteProfileTakeover from 'components/CompleteProfileTakeover'
import LanguageHandler from '@core/LanguageHandler'
import InitSegment from 'webapp/components/init/InitSegment'
import OpenRoutesInNewTab from 'webapp/components/init/OpenRoutesInNewTab'
import { InitBugsnag } from 'webapp/components/init/InitBugsnag'
import { InitProfile } from 'webapp/components/init/InitProfile'
import { InitCognito } from 'webapp/components/init/InitCognito'
import { InitFlagsmith } from 'webapp/components/init/InitFlagsmith'
import { GlobalModal } from '@common/GlobalModal'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment-timezone'
import { theme } from 'webapp/theme'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { withReactQuery } from 'webapp/components/withReactQuery'
import localForage from 'localforage'
import { Hydrate } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import '@core/utils/legacy-api-axios-instance'
import Hotjar from '@hotjar/browser'
import Project from '@core/project'
import { Chip } from '@common/material'
import { Box } from '@fundamentals'
import { ToastProvider } from '@common/toast'
import cookies from 'js-cookie'
import { GlobalDrawer } from '@common/GlobalDrawer'
import { PusherWrapper } from '@core/components/PusherWrapper'

export async function getInitialProps({ Component, ctx }) {
  let pageProps

  // todo: this shouldn't happen for static resources
  if (ctx.pathname === '/_error' || !ctx.pathname) {
    return
  }

  if (Component?.getInitialProps) {
    // Wait for pages to complete any async getInitialProps
    pageProps = await Component.getInitialProps({ ctx })
  }

  return { pageProps }
}

const siteId = 3467775
const hotjarVersion = 6
Hotjar.init(siteId, hotjarVersion)

const checkE2E = () => {
  if (typeof window !== 'undefined') {
    // E2E tests write to local storage so that the app runs in E2E mode
    // This changes some components to make them easier to handle in E2E tests
    const isE2E = window?.localStorage.getItem('sa.isE2E')
    if (isE2E) {
      Constants.E2E = true
    }
  }
}

class MyApp extends App<{ queryClient? }> {
  static getInitialProps = getInitialProps

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // if (window?.document?.getElementById("toast")) {
    //   ReactDOM.render(<Toast />, document.getElementById("toast"));
    // }
  }

  render() {
    // E2E tests write a cookie so that the app runs in E2E mode
    // This changes some components to make them easier to handle in E2E tests
    checkE2E()

    const { Component, pageProps, queryClient } = this.props
    // If you are not using SSR, you may wish to display a loader here until clientLoaded = true
    // if (!store.getState().clientLoaded) {
    //
    // }

    const getLayout = (page) =>
      // @ts-ignore
      Component.getLayout ? (
        // @ts-ignore
        <Component.getLayout page={page} router={this.props.router} />
      ) : (
        page
      )

    const asyncStoragePersister = createAsyncStoragePersister({
      storage: localForage,
    })

    const usesNest = cookies.get('sa_nest')

    return (
      <InitCognito>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister: asyncStoragePersister }}
          onSuccess={() => {
            // resume mutations after initial restore from localStorage was successful
            queryClient.resumePausedMutations()
          }}
        >
          <InitBugsnag>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              dateLibInstance={moment}
            >
              {/* @ts-ignore*/}
              <Hydrate state={pageProps?.dehydratedState}>
                <InitProfile>
                  <InitFlagsmith>
                    <IntercomProvider appId={Project.intercomAppId}>
                      <LanguageHandler>
                        <ThemeProvider theme={createTheme(theme)}>
                          <ToastProvider>
                            <InitSegment />
                            <PusherWrapper>
                              <GlobalModal>
                                <GlobalDrawer>
                                  <React.Fragment>
                                    <OpenRoutesInNewTab />
                                    {getLayout(
                                      <Component {...pageProps}></Component>,
                                    )}
                                    {Project.environmentChip && (
                                      <Box
                                        zIndex={1000}
                                        position={'absolute'}
                                        left={'45vw'}
                                        top={15}
                                      >
                                        <Chip
                                          label={Project.environmentChip}
                                          color='warning'
                                        />
                                      </Box>
                                    )}
                                    {usesNest && (
                                      <Box
                                        zIndex={1000}
                                        position={'absolute'}
                                        left={'45vw'}
                                        top={15}
                                      >
                                        <Chip
                                          label={'Nest API'}
                                          color='warning'
                                        />
                                      </Box>
                                    )}
                                    <div id='modal' />
                                    <div id='confirm' />
                                    <div id='alert' />
                                    <CompleteProfileTakeover />
                                    {E2E && (
                                      <React.Fragment>
                                        <div className='e2e' id='e2e-request' />
                                        <div className='e2e' id='e2e-error' />
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                </GlobalDrawer>
                              </GlobalModal>
                            </PusherWrapper>
                          </ToastProvider>
                        </ThemeProvider>
                        {!Constants.E2E && (
                          <ReactQueryDevtools initialIsOpen={false} />
                        )}
                      </LanguageHandler>
                    </IntercomProvider>
                  </InitFlagsmith>
                </InitProfile>
              </Hydrate>
            </LocalizationProvider>
          </InitBugsnag>
        </PersistQueryClientProvider>
      </InitCognito>
    )
  }
}

export default global.__JEST__ ? null : withReactQuery(MyApp)
