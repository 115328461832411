import { localizedStrings } from '@core/strings'
import React, { useEffect } from 'react'
import { Text } from '@fundamentals'
import { Menu, MenuItem, Button } from '..'
import { DropdownButtonProps } from './types'
import { styled, experimental_sx as sx } from '@mui/material/styles'
import { Tooltip } from '@mui/material'
import { Close } from '@mui/icons-material'

const StyledMenu = styled(Menu)<{ buttonWidth: number }>`
  & .MuiPaper-root {
    ${sx({
      mt: 0.5,
      '.MuiMenuItem-gutters': { px: 1 },
    })}
    ${({ buttonWidth }) => buttonWidth && { minWidth: buttonWidth }};
  }
`

const StyledButton = styled(Button)<{ collapsed: boolean }>`
  .MuiSvgIcon-root {
    font-size: 24px;
  }
  ${({ collapsed }) => {
    return (
      collapsed &&
      sx({
        '&.MuiButton-root': {
          justifyContent: 'center',
          padding: '0.5625rem',
          minWidth: '2.625rem',
          maxWidth: '2.625rem',
        },
        '.MuiButton-startIcon, .MuiButton-endIcon': { mx: 0 },
      })
    )
  }}
`

const DropdownButton: React.FC<DropdownButtonProps> = ({
  label,
  options,
  onSelect,
  fullWidth,
  denseOptions,
  collapsed,
  renderButton,
  withCloseOption,
  disableCloseOnSelect = false,
  onBlur,
  ...props
}) => {
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement | null>()
  const [buttonWidth, setButtonWidth] = React.useState(0)
  const anchorOffsetWidth = anchorElement?.offsetWidth || buttonWidth
  useEffect(() => {
    setButtonWidth(anchorOffsetWidth)
  }, [anchorOffsetWidth])

  const open = Boolean(anchorElement)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setAnchorElement(null)
    onBlur?.()
  }

  const optionsHaveIcons = options?.some((option) => option.icon)

  // If there are no options, then this element is not rendered
  if (!options?.length) {
    return null
  }

  return (
    <>
      <Tooltip title={label} disableHoverListener={!collapsed}>
        {renderButton ? (
          renderButton(handleClick)
        ) : (
          <StyledButton
            data-test='dropdown-btn'
            onClick={handleClick}
            fullWidth={fullWidth}
            collapsed={collapsed}
            {...props}
          >
            {!collapsed && label}
          </StyledButton>
        )}
      </Tooltip>

      {open && (
        <StyledMenu
          open={open}
          anchorEl={anchorElement}
          onClose={handleClose}
          buttonWidth={fullWidth && buttonWidth}
        >
          {options?.map(
            (
              {
                title,
                icon,
                value,
                onSelect: onItemSelect,
                color,
                tooltipTitle,
                ...option
              },
              index,
            ) => {
              return (
                <Tooltip key={index} title={tooltipTitle}>
                  <span>
                    <MenuItem
                      onClick={(e) => {
                        if (onItemSelect) {
                          onItemSelect(value)
                          onBlur?.()
                        }
                        if (onSelect) {
                          onSelect(value)
                          onBlur?.()
                        }
                        if (!disableCloseOnSelect) handleClose(e)
                      }}
                      dense={denseOptions}
                      sx={{
                        color: color,
                        '.MuiSvgIcon-root': { color: color || 'grey.600' },
                      }}
                      {...option}
                    >
                      {icon}
                      <Text
                        ml={icon && 1}
                        data-test={`dropdown-option-${index}`}
                      >
                        {title}
                      </Text>
                    </MenuItem>
                  </span>
                </Tooltip>
              )
            },
          )}
          {withCloseOption && (
            <MenuItem key={'close'} onClick={handleClose} dense={denseOptions}>
              {optionsHaveIcons && <Close />}
              <Text
                ml={optionsHaveIcons ? 1 : 0}
                data-test={`dropdown-option-close`}
              >
                {localizedStrings.close}
              </Text>
            </MenuItem>
          )}
        </StyledMenu>
      )}
    </>
  )
}

export { DropdownButton }
