import { stringify, ParsedUrlQueryInput } from 'querystring'
import { PermitListTabs, PermitStatus } from '@core/entities/permit/PermitBase'
import {
  ChecklistListTabs,
  ChecklistStatus,
} from '@core/entities/checklist/Checklist/types'

const queryString = (query: ParsedUrlQueryInput) => {
  const stringified = stringify(query)
  if (stringified) {
    return `?${stringified}`
  } else {
    return ''
  }
}

export const PageUrls = {
  actions: (id: number | string) => `/organisations/${id}/actions`,
  adminAnalytics: `/support/analytics`,
  adminUser: (id: number | string) => `/support/users/${id}`,
  adminOrganisations: `/support/organisations`,
  adminUsers: `/support/users`,
  adminPermits: `/support/permits`,
  addressRequestedPermitChanges: (args: {
    organisationId: number | string
    permitId: number | string
  }) => `/organisations/${args.organisationId}/permits/${args.permitId}/change`,
  addressRequestedProjectPermitChanges: (args: {
    organisationId: number
    projectId: number
    permitId: number
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/permits/${args.permitId}/change`,
  contacts: (id: number | string) => `/organisations/${id}/contacts`,
  createOrganisation: `/support/organisations/create`,
  createTemplate: (id: number | string, folderId?: number | string) =>
    `/organisations/${id}/settings/permit-templates/create?folderId=${folderId}`,
  createChecklistTemplate: (id: number | string, folderId?: number | string) =>
    `/organisations/${id}/settings/checklist-templates/create?folderId=${folderId}`,
  editTemplate: (orgId: number | string, templateId) =>
    `/organisations/${orgId}/settings/permit-templates/${templateId}`,
  editChecklistTemplate: (orgId: number | string, templateId) =>
    `/organisations/${orgId}/settings/checklist-templates/${templateId}`,
  editRegisterTemplate: ({
    orgId,
    templateId,
    step,
  }: {
    orgId: number | string
    templateId: number | string
    step?: string
  }) => {
    let url = `/organisations/${orgId}/settings/register-templates/${templateId}`
    if (step) {
      url += `?step=${step}`
    }
    return url
  },
  editTemplateVersion: ({ orgId, templateId, versionId }) =>
    `/organisations/${orgId}/settings/permit-templates/${templateId}/versions/${versionId}`,
  editChecklistTemplateVersion: ({ orgId, templateId, versionId }) =>
    `/organisations/${orgId}/settings/checklist-templates/${templateId}/versions/${versionId}`,
  createTemplatePermitRole: ({ orgId, templateId, versionId }) =>
    `/organisations/${orgId}/settings/permit-templates/${templateId}/versions/${versionId}/permit-roles/create`,
  editTemplatePermitRole: ({ orgId, templateId, versionId, id }) =>
    `/organisations/${orgId}/settings/permit-templates/${templateId}/versions/${versionId}/permit-roles/${id}`,
  createTemplatePeriodicCheck: ({ orgId, templateId, versionId }) =>
    `/organisations/${orgId}/settings/permit-templates/${templateId}/versions/${versionId}/periodic-checks/create`,
  editTemplatePeriodicCheck: ({ orgId, templateId, versionId, id }) =>
    `/organisations/${orgId}/settings/permit-templates/${templateId}/versions/${versionId}/periodic-checks/${id}`,
  organisationViewTemplateVersion: ({
    orgId,
    templateId,
    versionId,
    stage,
  }: {
    orgId: number | string
    templateId: number | string
    versionId: number | string
    stage?: 'authorisation' | 'settings'
  }) => {
    let url = `/organisations/${orgId}/settings/permit-templates/${templateId}/versions/${versionId}`
    if (stage) {
      url += `?tab=${stage}`
    }
    return url
  },
  projectViewTemplateVersion: ({
    orgId,
    templateId,
    versionId,
    projectId,
    stage,
  }: {
    orgId: number | string
    projectId: number | string
    templateId: number | string
    versionId: number | string
    stage?: 'authorisation' | 'settings'
  }) => {
    let url = `/organisations/${orgId}/projects/${projectId}/settings/permit-templates/${templateId}/versions/${versionId}`
    if (stage) {
      url += `?stage=${stage}`
    }
    return url
  },
  projectViewChecklistTemplateVersion: ({
    orgId,
    templateId,
    versionId,
    projectId,
    tab,
  }: {
    orgId: number | string
    projectId: number | string
    templateId: number | string
    versionId: number | string
    tab?: 'authorisation' | 'settings'
  }) => {
    let url = `/organisations/${orgId}/projects/${projectId}/settings/checklist-templates/${templateId}/versions/${versionId}`
    if (tab) {
      url += `?tab=${tab}`
    }
    return url
  },
  home: `/`,
  inspections: (id: number | string) => `/organisations/${id}/inspections`,
  organisation: (id: number | string) => `/organisations/${id}`,
  organisationInviteUrl: `/organisations/accept-invite`,
  permits: (
    orgId: number | string,
    query?: {
      projectIds?: number[]
      statuses?: string[]
      templateIds?: number[]
      discipline?: number
      sort?: string
      tab?: PermitListTabs
      hideTestProjects?: boolean
    },
    suffix = '',
  ) => {
    return `/organisations/${orgId}/permits${suffix}${queryString(query)}`
  },
  checklists: (
    orgId: number | string,
    query?: {
      projectIds?: number | string
      statuses?: string[]
      sort?: string
      tab?: ChecklistListTabs
    },
    suffix = '',
  ) => {
    return `/organisations/${orgId}/checklists${suffix}${queryString(query)}`
  },
  registers: (
    orgId: number | string,
    query?: {
      term?: string
      status?: string[]
      tab?
    },
    suffix = '',
  ) => {
    return `/organisations/${orgId}/registers${suffix}${queryString(query)}`
  },
  newPermit: (orgId: number | string) => `/organisations/${orgId}/permits/new`,
  newPermitProject: (args: { organisationId: number; projectId: number }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/permits/new`,
  permitApproverQuestions: (orgId: number | string, id: number | string) => {
    return `/organisations/${orgId}/permits/${id}/approve`
  },
  projectPermitApproverQuestions: (
    orgId: number | string,
    projectId: number | string,
    id: number | string,
  ) => {
    return `/organisations/${orgId}/projects/${projectId}/permits/${id}/approve`
  },
  permitFinalSignOffs: (orgId: number | string, id: number | string) => {
    return `/organisations/${orgId}/permits/${id}/final-signoffs`
  },
  projectPermitFinalSignOffs: (
    orgId: number | string,
    projectId: number | string,
    id: number | string,
  ) => {
    return `/organisations/${orgId}/projects/${projectId}/permits/${id}/final-signoffs`
  },
  permitSignoffCheck: (args: { organisationId: number; permitId: number }) => {
    return `/organisations/${args.organisationId}/permits/${args.permitId}/signoff`
  },
  projectPermitSignoffCheck: (args: {
    organisationId: number
    projectId: number
    permitId: number
  }) => {
    return `/organisations/${args.organisationId}/projects/${args.projectId}/permits/${args.permitId}/signoff`
  },
  newChecklist: (args: { organisationId: number | string }) =>
    `/organisations/${args.organisationId}/checklists/new`,
  newChecklistProject: (args: { organisationId: number; projectId: number }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/checklists/new`,
  permit: (orgId: number | string, id: number | string) =>
    `/organisations/${orgId}/permits/${id}`,
  checklist: (orgId: number | string, id: number | string) =>
    `/organisations/${orgId}/checklists/${id}`,
  permitDraft: (orgId: number | string, id: number | string) =>
    `/organisations/${orgId}/permits/draft/${id}`,
  checklistDraft: (orgId: number | string, id: number | string) =>
    `/organisations/${orgId}/checklists/draft/${id}`,
  receivePermit: (organisationId: number, permitId: number) =>
    `/organisations/${organisationId}/permits/${permitId}/receive`,
  receiveProjectPermit: (
    organisationId: number,
    projectId: number,
    permitId: number,
  ) =>
    `/organisations/${organisationId}/projects/${projectId}/permits/${permitId}/receive`,
  profile: (args: { organisationId: string | number }) =>
    `/organisations/${args.organisationId}/profile`,
  project: (orgId: number | string, id: number | string) =>
    `/organisations/${orgId}/projects/${id}`,
  projectOverview: (params: {
    organisationId: number | string
    projectId: number | string
    query?: {
      fullScreen?: boolean
      tab?: 'Overview'
    }
  }) => {
    return `/organisations/${params.organisationId}/projects/${
      params.projectId
    }/overview${queryString(params.query)}`
  },
  projectPermitMap: (args: { organisationId: number; projectId: number }) => {
    return `/organisations/${args.organisationId}/projects/${args.projectId}/permitMap`
  },
  projectMap: (args: { organisationId: number; projectId: number }) => {
    return `/organisations/${args.organisationId}/projects/${args.projectId}/map`
  },
  projectPermits: (
    orgId: number | string,
    id: number | string,
    query?: {
      statuses?: PermitStatus[]
      sort?: string
      templateIds?: number[] | string[]
      locationIds?: number[] | string[]
      discipline?: number
      tab?: PermitListTabs
    },
  ) => {
    return `/organisations/${orgId}/projects/${id}/permits${queryString(query)}`
  },
  projectPermitDetail: (params: {
    organisationId: number | string
    projectId: number | string
    permitId: number | string
  }) =>
    `/organisations/${params.organisationId}/projects/${params.projectId}/permits/${params.permitId}`,
  projectChecklists: (args: {
    organisationId: number
    projectId: number
    query?: {
      projectIds?: number | string
      statuses?: string[]
      sort?: string
      tab?: ChecklistListTabs
    }
  }) => {
    return `/organisations/${args.organisationId}/projects/${
      args.projectId
    }/checklists${queryString(args.query)}`
  },
  projectChecklistDetail: (params: {
    organisationId: number | string
    projectId: number | string
    checklistId: number | string
  }) =>
    `/organisations/${params.organisationId}/projects/${params.projectId}/checklists/${params.checklistId}`,
  organisationTimeline: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/timeline`,
  projectTimeline: (orgId: number | string, id) =>
    `/organisations/${orgId}/projects/${id}/timeline`,
  projectUsers: (orgId: number | string, id) =>
    `/organisations/${orgId}/projects/${id}/users`,
  organisationUsers: (orgId) => `/organisations/${orgId}/users`,
  organisationAuthorisationGroupList: (args: {
    organisationId: number | string
  }) => `/organisations/${args.organisationId}/settings/groups`,
  projectAuthorisationGroupList: (args: {
    organisationId: number | string
    projectId: number | string
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/groups`,
  projectSettings: (args: { organisationId: number; projectId: number }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings`,
  projectSettingsGeneral: (args: {
    organisationId: number
    projectId: number
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/general`,
  organisationAuthorisationGroup: (args: {
    organisationId: number | string
    groupId: number | string
  }) => `/organisations/${args.organisationId}/settings/groups/${args.groupId}`,
  projectSiteBox: (args: {
    organisationId: number
    projectId: number
    query?: {
      folderId?: number
    }
  }) => {
    return `/organisations/${args.organisationId}/projects/${
      args.projectId
    }/site-box${queryString(args.query)}`
  },
  projectSiteBoxDocument: (args: {
    organisationId: number
    projectId: number
    folderId: number
    documentId: number
    locationArchived: boolean
    useImage?: boolean
  }) => {
    const query = queryString({
      folderId: args.folderId,
      documentId: args.documentId,
      locationArchived: args.locationArchived,
      useImage: args.useImage,
    })
    return `/organisations/${args.organisationId}/projects/${args.projectId}/site-box/document${query}`
  },
  projectAuthorisationGroup: (args: {
    organisationId: number | string
    projectId: number | string
    groupId: number | string
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/groups/${args.groupId}`,
  projectLocations: (args: { organisationId: number; projectId: number }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/locations`,
  projectLocationPlan: (args: {
    organisationId: number
    projectId: number
    nodeId?: string
    planId?: number
    nodePath: string
    locationArchived: boolean
    useImage?: boolean
  }) => {
    const query = queryString({
      nodeId: args?.nodeId,
      planId: args?.planId,
      nodePath: args.nodePath,
      locationArchived: args.locationArchived,
      useImage: args.useImage,
    })
    return `/organisations/${args.organisationId}/projects/${args.projectId}/settings/locations/plan${query}`
  },
  projectMapSettings: (args: { organisationId: number; projectId: number }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/map`,
  projectViewPlans: (orgId: number | string, id) =>
    `/organisations/${orgId}/projects/${id}/view-plans`,
  projectRegisters: (orgId: number | string, id) =>
    `/organisations/${orgId}/projects/${id}/registers`,
  registerDetails: (args: {
    organisationId: number | string
    registerId: number
    query?: ParsedUrlQueryInput
  }) => {
    const urlQuery = queryString(args?.query ?? {})
    return `/organisations/${args.organisationId}/registers/${args.registerId}${urlQuery}`
  },
  organisationRegisterDetailsDiagram: (args: {
    organisationId: number | string
    registerId: number
    query?: ParsedUrlQueryInput
  }) => {
    const urlQuery = queryString(args?.query ?? {})
    return `/organisations/${args.organisationId}/registers/${args.registerId}/diagrams${urlQuery}`
  },
  projectRegisterDetailsDiagram: (args: {
    organisationId: number | string
    projectId: number | string
    registerId: number
    query?: ParsedUrlQueryInput
  }) => {
    const urlQuery = queryString(args?.query ?? {})
    return `/organisations/${args.organisationId}/projects/${args.projectId}/registers/${args.registerId}/diagrams${urlQuery}`
  },
  registerSettings: (orgId: number | string, registerId: number) =>
    `/organisations/${orgId}/registers/${registerId}/settings`,
  projectRegisterDetails: (args: {
    organisationId: number | string
    projectId: number | string
    registerId: number
    query?: ParsedUrlQueryInput
  }) => {
    const urlQuery = queryString(args?.query ?? {})
    return `/organisations/${args.organisationId}/projects/${args.projectId}/registers/${args.registerId}${urlQuery}`
  },
  projectRegisterSettings: (orgId: number | string, id, registerId: number) =>
    `/organisations/${orgId}/projects/${id}/registers/${registerId}/settings`,
  registerItemDetails: (args: {
    organisationId: number
    registerId: number
    registerItemId: number
    registerViewId?: number
  }) =>
    `/organisations/${args.organisationId}/registers/${args.registerId}/items/${args.registerItemId}?viewId=${args.registerViewId}`,
  projectRegisterItemDetails: (args: {
    organisationId: number
    projectId: number
    registerId: number
    registerItemId: number
    viewId?: number
  }) => {
    const query = queryString({
      viewId: args.viewId,
    })
    return `/organisations/${args.organisationId}/projects/${args.projectId}/registers/${args.registerId}/items/${args.registerItemId}${query}`
  },
  projectPermitTemplates: (args: {
    organisationId: number
    projectId: number
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/permit-templates`,
  projectChecklistTemplates: (args: {
    organisationId: number
    projectId: number
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/settings/checklist-templates`,
  projectTemplateDetail: (
    orgId: number | string,
    projectId: number | string,
    templateId: number | string,
  ) =>
    `/organisations/${orgId}/projects/${projectId}/settings/permit-templates/${templateId}`,
  projects: (id: number | string) => `/organisations/${id}/projects`,
  businessUnits: (id: number | string) => `/organisations/${id}/business-units`,
  businessUnitOverview: ({
    id,
    businessUnitId,
  }: {
    id: string | number
    businessUnitId: string | number
  }) => `/organisations/${id}/business-units/${businessUnitId}/overview`,
  businessUnitProjects: ({
    id,
    businessUnitId,
  }: {
    id: string | number
    businessUnitId: string | number
  }) => `/organisations/${id}/business-units/${businessUnitId}/projects`,
  businessUnitEdit: ({
    id,
    businessUnitId,
  }: {
    id: string | number
    businessUnitId: string | number
  }) => `/organisations/${id}/business-units/${businessUnitId}/edit`,
  businessUnitPermits: ({
    id,
    businessUnitId,
    query,
  }: {
    id: number | string
    businessUnitId: number | string
    query?: { statuses?: PermitStatus[]; sort?: string }
  }) => {
    return `/organisations/${id}/business-units/${businessUnitId}/permits${queryString(
      query,
    )}`
  },
  businessUnitChecklists: ({
    id,
    businessUnitId,
    query,
  }: {
    id: number | string
    businessUnitId: number | string
    query?: { statuses?: ChecklistStatus[]; sort?: string }
  }) => {
    return `/organisations/${id}/business-units/${businessUnitId}/checklists${queryString(
      query,
    )}`
  },
  organisationSettings: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/settings`,
  organisationGeneralSettings: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/settings/general`,
  organisationPermitTemplates: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/settings/permit-templates`,
  organisationChecklistTemplates: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/settings/checklist-templates`,
  organisationRegisterTemplates: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/settings/register-templates`,
  organisationProcessTags: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/settings/tags`,
  organisationAnalytics: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/analytics`,
  organisationOpenAIAnalytics: (args: { organisationId: number }) =>
    `/organisations/${args.organisationId}/analytics/open-ai`,
  organisationAnalyticsDashboard: (args: {
    organisationId: number
    dashboardId: number
  }) =>
    `/organisations/${args.organisationId}/analytics/dashboards/${args.dashboardId}`,
  projectAnalytics: (args: { organisationId: number; projectId: number }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/analytics`,
  projectAnalyticsDashboard: (args: {
    organisationId: number
    projectId: number
    dashboardId: number
  }) =>
    `/organisations/${args.organisationId}/projects/${args.projectId}/analytics/dashboards/${args.dashboardId}`,
  businessUnitAnalytics: (args: {
    organisationId: number
    businessUnitId: number
  }) =>
    `/organisations/${args.organisationId}/business-units/${args.businessUnitId}/analytics`,
  businessUnitAnalyticsDashboard: (args: {
    organisationId: number
    businessUnitId: number
    dashboardId: number
  }) =>
    `/organisations/${args.organisationId}/business-units/${args.businessUnitId}/analytics/dashboards/${args.dashboardId}`,
  linkedOrganisations: (id: number | string) =>
    `/organisations/${id}/settings/links`,
  reports: (id: number | string) => `/organisations/${id}/reports`,
  mobileBriefing: (args: {
    checklistId: number
    accessToken: string
    organisationId: number
    projectId: number
  }) => `/mobile/briefing${queryString(args)}`,
  forgotPassword: (query?: { email?: string }) =>
    `/forgot-password${queryString(query)}`,
  forgotPasswordEmailSent: '/forgot-password-email-sent',
  noOrganisation: '/no-organisation',
  resetPassword: '/reset-password',
  resetPasswordSuccess: '/reset-password-success',
  federatedLoginRedirect: () => {
    return `${document.location.origin}/oauth/web-redirect`
  },
}
